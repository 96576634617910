import React, { useState, useEffect } from 'react';

import { Avatar, Divider, Box, Image, Switch, Card, Grid, Skeleton } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating'
import moment from 'moment';

import { FiLink, FiEdit, FiTrash, FiEye, FiMoreVertical, } from "react-icons/fi";

import Loading from '../../../../components/Loading/Loading';


// Utility-Service
import { getToken } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service
import { serviceDetail, UpdateStatusService } from '../../../../service/bookingService';

// Redux-Settings
import {
    setSingleData,
    selectStateData,
    selectStateSingleData
} from '../../../../redux/bookingSlice';

// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';



const BookingDetail = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [getBookingDetail, setBookingDetail] = useState(null)
    const { id } = useParams()
    const token = getToken();

    const getSingleData = async () => {
        const result = await serviceDetail({ id, token });
        if (result?.data.status) {
            setLoading(false);
            setBookingDetail(result?.data.data)
        } else {
            setLoading(false);
            ErrorToast(result);
        }
    };

    useEffect(() => {
        getSingleData();
        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, []);

    const statusUpdate = async (item) => {
        const statusUpdateresult = await UpdateStatusService({ id: item.id, token });
        if (statusUpdateresult?.data?.status) {
            ErrorToast(statusUpdateresult?.data?.message);
            SuccessToast()
        } else {
            ErrorToast(statusUpdateresult);
        }
    };

    if (loading && getBookingDetail === null) {
        return <Loading />
    }


    if (getBookingDetail?.booking_code) {
        return (
            <Card>
                <Grid>
                    <Grid.Col span={12}>
                        <h3>Booking Detail</h3>
                    </Grid.Col>
                    <Grid.Col span={2} >
                        <Avatar variant="outline" size='xl' color="violet" component={Link} to="/my-route/" src={getBookingDetail?.customer?.profile_image} />
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <h6>Name</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.customer.name ? getBookingDetail?.customer.name : '-'}</p>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <h6>Email</h6>
                            <Divider my="sm" />
                            <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                {getBookingDetail?.customer?.email ? getBookingDetail?.customer?.email : '-'}
                            </p>
                        </div>
                    </Grid.Col>

                    <Grid.Col span={2}>
                        <div>
                            <h6>Phone</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.customer?.phone ? getBookingDetail?.customer?.phone : '-'}</p>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <h6>Booking Code</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.booking_code ? getBookingDetail?.booking_code : '-'}</p>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <h6>Car Brand</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.car_brand ? getBookingDetail?.car_brand : '-'}</p>

                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <h6>Car Model</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.car_model ? getBookingDetail?.car_model : '-'}</p>
                        </div>
                    </Grid.Col>

                    <Grid.Col span={2}>
                        <div>
                            <h6>Car Year</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.car_year ? getBookingDetail?.car_year : '-'}</p>
                        </div>
                    </Grid.Col>

                    <Grid.Col span={2}>
                        <div>
                            <h6>Fuel Type</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.fuel_type ? getBookingDetail?.fuel_type : '-'}</p>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <h6>Transmission</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.transmission ? getBookingDetail?.transmission : '-'}</p>
                        </div>
                    </Grid.Col>
                    <Grid.Col xs={2}>
                        <div>
                            <h6>Cancel By</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.canceled_by ? <p>{getBookingDetail?.canceled_by}</p> : <p>--</p>}

                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <h6>Created Date</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.created_at ? moment(getBookingDetail.created_at).format("DD-MM-YYYY") : '-'}</p>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <div>
                            <h6>User Complaint</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.report_reason ? <p>{getBookingDetail?.report_reason}</p> : <p>--</p>}

                        </div>
                    </Grid.Col>
                    <Grid.Col xs={6}>
                        <div>
                            <h6>Extra Payment Request</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.extra_payment.length > 0 ?
                                <div className='mechanicList'>
                                    {getBookingDetail?.extra_payment?.map(item => (
                                        <p> {item.description} || {`RM ${item.amount}`}</p>
                                    ))}
                                </div>
                                : '-'}
                        </div>
                    </Grid.Col>
                    <Grid.Col xs={12} md={6}>
                        <div>
                            <h6>Cancel Reason</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.cancel_reason ? <p>{getBookingDetail?.cancel_reason}</p> : <p>No Remark</p>}

                        </div>
                    </Grid.Col>

                    <Grid.Col xs={12} md={6}>
                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Rating & Review</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.ratting && getBookingDetail.ratting.length > 0 ? (
                                getBookingDetail.ratting.map((rating) => (
                                    <Box
                                        key={rating.id}
                                        sx={{
                                            marginBottom: '1rem',
                                            padding: '1rem',
                                            border: '1px solid #e0e0e0',
                                            borderRadius: '8px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '0.5rem'
                                        }}
                                    >
                                        {/* Stars and Review in a column */}
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            <strong style={{ whiteSpace: 'nowrap', marginBottom: '4px' }}>Rating:</strong>
                                            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '2px', marginBottom: '8px' }}>
                                                <Rating readonly initialValue={rating.stars} size={20}  allowFraction />
                                            </div>
                                        </div>
                                        <div>
                                            <strong>Review:</strong> {rating.review}
                                        </div>
                                    </Box>
                                ))
                            ) : (
                                <p>--</p>
                            )}
                        </div>
                    </Grid.Col>

                    <Grid.Col xs={6}>
                        <div>
                            <h6>Pickup Date</h6>
                            <Divider my="sm" />
                            <p>{getBookingDetail?.
                                pick_date
                                ? moment(getBookingDetail.
                                    pick_date
                                ).format("DD-MM-YYYY") : '-'}</p>


                        </div>
                    </Grid.Col>

                    <Grid.Col xs={6}>
                        <div>
                            <h6>Pickup Time</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.pick_time ? <p>{getBookingDetail?.pick_time} </p> : <p>No time</p>}

                        </div>
                    </Grid.Col>

                    <Grid.Col xs={12}>

                        <div>
                            <h6 style={{ marginBottom: '0.5rem' }}>Damage Images</h6>
                            <Divider my="sm" />
                            {getBookingDetail && getBookingDetail?.damage_images && getBookingDetail?.damage_images.length > 0 ? (
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {getBookingDetail?.damage_images.map(item => (
                                        <a
                                            key={item.name}
                                            href={item.path}
                                            target="_blank"
                                            rel="noopener noreferrer"

                                            style={{ cursor: 'pointer', textDecoration: 'none' }}
                                        >

                                            <Image
                                                src={item.path}
                                                alt="Damage Image"
                                                sx={(_) => ({
                                                    height: '140px',
                                                    width: '100px',
                                                    border: '1px solid black',
                                                    margin: '3px 5px',
                                                    overflow: 'hidden',
                                                })}

                                            />

                                        </a>
                                    ))}
                                </div>
                            ) : (
                                <p>No Damage Image</p>
                            )}
                        </div>

                    </Grid.Col>
                    <Grid.Col span={12}>
                        <div>
                            <h6>Problem Summary Voice</h6>
                            <Divider my="sm" />
                            {/* { getBookingDetail?.summary_voice ? <p>{getBookingDetail?.summary_voice}</p> : <p>--</p>} */}
                            <audio controls>
                                <source src={getBookingDetail?.summary_voice} type="audio/mpeg" />
                                <track src="my" kind="captions" label="english_captions" />
                                <track src="./my.mp3" kind="captions" label="spanish_captions" />
                            </audio>

                        </div>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <div>
                            <h6>Problem Summary</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.summary_text ? <p>{getBookingDetail?.summary_text}</p> : <p>--</p>}

                        </div>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <div>
                            <h6>Job Location</h6>
                            <Divider my="sm" />
                            {getBookingDetail?.current_location ? <p>{getBookingDetail?.current_location}</p> : <p>--</p>}

                        </div>
                    </Grid.Col>
                    <Grid.Col>
                        <div>
                            <h6 style={{ marginTop: '0.9rem', marginBottom: '0.5rem' }}>Services</h6>
                            <Divider my="sm" />
                            {getBookingDetail && getBookingDetail.service_details && getBookingDetail.service_details.length > 0 ? (
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {getBookingDetail.service_details.map(item => (
                                        <div className='serviceClass' key={item.id}>
                                            <div>
                                                <span>
                                                    <Image
                                                        src={item.image}
                                                        alt="Avatar Thumb"
                                                        sx={(_) => ({
                                                            height: '60px',
                                                            width: '60px',
                                                            overflow: 'hidden',
                                                            margin: 'auto',
                                                        })}
                                                    />
                                                </span>
                                                <p>{item.name}</p>
                                                {item?.is_other_service &&
                                                    <p>{getBookingDetail?.other_services}</p>
                                                }

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p>No services available</p>
                            )}
                        </div>

                    </Grid.Col>
                </Grid>



            </Card >
        )

    }

    return (
        <>
            <Card>
                <Grid>
                    <Grid.Col span={12}>
                        <Skeleton height={30} width="60%" radius="sm" />
                    </Grid.Col>
                    {[...Array(6)].map((_, idx) => (
                        <Grid.Col span={2}>
                            <Skeleton height={80} radius="sm" />
                        </Grid.Col>
                    ))}
                    <Grid.Col span={12}>
                        <Skeleton height={190} radius="sm" />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Skeleton height={190} radius="sm" />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Skeleton height={150} radius="sm" />
                    </Grid.Col>
                </Grid>
            </Card>
        </>

    )


};

export default BookingDetail;

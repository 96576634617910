
import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";


// Auth-Service
import PrivateRoute from '../utils/PrivateRoute';

// Pages
import LoginPage from './Pages/Login/Login';
import LoginPageV2 from './Pages/Login/LoginV2';
import LoginPageV3 from './Pages/Login/LoginV3';
import LayoutPage from './Pages/Layout/Layout';
// import Dashboard from './Pages/Dashboard/Dashboard';
import TermsAgrement from './Pages/TermsAgreement/TermsAgreement';
import Cards from './Pages/Cards/Cards';
import ImageUploaderCard from  './Pages/ImageUploader/ImageUploader';
import Elements from './Pages/Elements/Elements';

/// My Routes 
import Dashboard from './Pages/admin/Dashboard/index';
import Users from './Pages/admin/Users/index';
import Services from './Pages/admin/Services/index';
import Customers from './Pages/admin/Customers/index';
import Transactions from './Pages/admin/Transactions/index';
import Mechanics from './Pages/admin/Mechanics/index';
import Detail from './Pages/admin/Mechanics/Detail';
import MechanicProfile from './Pages/admin/Mechanics/Update';
import Bookings from './Pages/admin/Bookings/index';
import BookingDetail from './Pages/admin/Bookings/Detail';
import ServiceBookings from './Pages/admin/ServiceBooking/index';
import ServiceBookingsDetail from './Pages/admin/ServiceBooking/Detail'
import Coupons from './Pages/admin/Coupons/index';
import AddCoupon from './Pages/admin/Coupons/Create';
import EditCoupon from './Pages/admin/Coupons/Update';
import Topups from './Pages/admin/Topups/index';
import Withdrawals from './Pages/admin/Withdrawals/index';
import Contacts from './Pages/admin/Contacts/index';
import About from './Pages/admin/StringManagement/MainPage';
import ListMechanicString from './Pages/admin/StringManagement/mechanicTable';
import AddMechanicString from './Pages/admin/StringManagement/MechanicEditStringPage';
import ListUserString from './Pages/admin/StringManagement/userTable';
import AddUserString from './Pages/admin/StringManagement/UserEditStringPage';
import Notification from './Pages/admin/Notifications/index';
import AddNotification from './Pages/admin/Notifications/Create';
import UsersComplain from './Pages/admin/UsersComplain/index';
import Banks from './Pages/admin/Banks/index';
import Plans from './Pages/admin/Plans/selectString';
import AddUserCharge from './Pages/admin/Plans/addChargeUser';
import AddMechanicCharge from './Pages/admin/Plans/addChargeMechanic';
import SosUser from './Pages/admin/SosUser/index';

// Webpages
// 1) Customer
import AboutUsCustomer from './Pages/openPage/customer/about'
import AboutUsCustomerMalay from './Pages/openPage/customer/malayAboutUs'
import TermandConditionCustomer from './Pages/openPage/customer/termCondition'
import TermandConditionCustomerMalay from './Pages/openPage/customer/termMalay'
import PrivacyPolicy from './Pages/openPage/customer/policy'
import PolicyPageMalay from './Pages/openPage/customer/malayPolicyPage'
import Faqs from './Pages/openPage/customer/faq'
import FaqsMalay from './Pages/openPage/customer/malayFaq'
// 2) Mechanic
import AboutusMechanic from './Pages/openPage/mechanic/aboutUs'
import AboutusMechanicMalay from './Pages/openPage/mechanic/malayAboutUs'
import TermandConditionMechanic from './Pages/openPage/mechanic/termCondition'
import TermandConditionMechanicMalay from './Pages/openPage/mechanic/termsMalay'
import Policy from './Pages/openPage/mechanic/policyPage'
import PolicyMalay from './Pages/openPage/mechanic/malayPolicyPage'
import FaqMechanic from './Pages/openPage/mechanic/faq'
import FaqMechaicMalay from './Pages/openPage/mechanic/faqMalay'


// Component as pages
import PostDetails from '../components/PostDetails/PostDetails';
import Profile from './Pages/auth/profileChange';
import Password from './Pages/auth/forgetPassword';

// Delete Accont
import OtpGenration from './Pages/openPage/deleteAccount/generateOtpPage'
import VerificationPage from './Pages/openPage/deleteAccount/deleteOtpPage';
import DeletePage from './Pages/openPage/deleteAccount/verify-OtpPage';
import SucessPage from './Pages/openPage/deleteAccount/sucessOtpPage';
  
const DashboardApp = () =>(
    <BrowserRouter>
        <Routes>
            <Route path="/login" element={<LoginPageV3 />} />
            <Route path="/*" element={<LoginPageV3 />} />
               {/* Web page */}
                    <Route >
                        <Route path="/customer/aboutUs" element={<AboutUsCustomer/>}/>
                    </Route>
                    <Route >
                        <Route path="/customer/aboutUs/malay" element={<AboutUsCustomerMalay/>}/>
                    </Route>
                    <Route >
                        <Route path="/customer/faqs" element={<Faqs/>}/>
                    </Route>
                    <Route >
                        <Route path="/customer/faqs/malay" element={<FaqsMalay/>}/>
                    </Route>
                    <Route >
                        <Route path="/customer/policy" element={<PrivacyPolicy/>}/>
                    </Route>
                    <Route >
                        <Route path="/customer/policy/malay" element={<PolicyPageMalay/>}/>
                    </Route>
                    <Route >
                        <Route path="/customer/TermCondition" element={<TermandConditionCustomer/>}/>
                    </Route>
                    <Route >
                        <Route path="/customer/termCondition/malay" element={<TermandConditionCustomerMalay/>}/>
                    </Route>
                    <Route >
                        <Route path="/mechanic/aboutUs" element={<AboutusMechanic/>}/>
                    </Route>
                    <Route >
                        <Route path="/mechanic/aboutUs/malay" element={<AboutusMechanicMalay/>}/>
                    </Route>
                    <Route >
                        <Route path="/mechanic/faqs" element={<FaqMechanic/>}/>
                    </Route>
                    <Route >
                        <Route path="/mechanic/faqs/malay" element={<FaqMechaicMalay/>}/>
                    </Route>
                    <Route >
                        <Route path="/mechanic/TermCondition" element={<TermandConditionMechanic/>}/>
                    </Route>
                    <Route >
                        <Route path="/mechanic/termCondition/malay" element={<TermandConditionMechanicMalay/>}/>
                    </Route>
                    <Route >
                        <Route path="/mechanic/policy" element={<Policy/>}/>
                    </Route>
                    <Route >
                        <Route path="/mechanic/policy/malay" element={<PolicyMalay/>}/>
                    </Route>
                    {/* Delete Account */}  
                    <Route >
                        <Route path="/delete-account" element={<OtpGenration/>}/>
                    </Route>
                    <Route >
                        <Route path="/otp-verification" element={<VerificationPage/>}/>
                    </Route>
                    <Route >
                        <Route path="/delete-verification" element={<DeletePage/>}/>
                    </Route>
                    <Route >
                        <Route path="/sucess-page" element={<SucessPage/>}/>
                    </Route>
            <Route element={<PrivateRoute/>}>
                <Route element={<LayoutPage/>}>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/" element={<Dashboard/>} />
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/dashboard" element={<Dashboard/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="agrement" element={<TermsAgrement/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="cards" element={<Cards/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="image_uploader" element={<ImageUploaderCard/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="post_details" element={<PostDetails/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="elements" element={<Elements/>}/>
                    </Route>

                     {/* My routes starts */}
                     <Route element={<PrivateRoute/>}>
                        <Route path="/admin/users" element={<Users/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/profile" element={<Profile/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/change-password" element={<Password/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/customers" element={<Customers/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/services" element={<Services/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/banks" element={<Banks/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/plans" element={<Plans/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/plans/mechanic" element={<AddMechanicCharge/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/plans/freelancer" element={<AddUserCharge/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/transactions" element={<Transactions/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/mechanics" element={<Mechanics/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/mechanic-detail/:id" element={<Detail/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/update-mechanic-profile/:id" element={<MechanicProfile/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/bookings" element={<Bookings/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/booking-detail/:id" element={<BookingDetail/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/service-bookings" element={<ServiceBookings/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/service-bookings/:id" element={<ServiceBookingsDetail/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/topup" element={<Topups/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/coupons" element={<Coupons/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/add-coupon" element={<AddCoupon/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/edit-coupon/:id" element={<EditCoupon/>}/>
                    </Route>

                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/notifications" element={<Notification/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/create-notification" element={<AddNotification/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/contacts" element={<Contacts/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/string" element={<About/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/user/string/list" element={<ListUserString/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/mechanic/string/list" element={<ListMechanicString/>}/>
                    </Route>
 
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/withdrawals" element={<Withdrawals/>}/>
                    </Route>
                
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/mechanic/string" element={<AddMechanicString/>}/>
                    </Route>

                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/user/string" element={<AddUserString/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/user-complains" element={<UsersComplain/>}/>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/admin/sos-user" element={<SosUser/>}/>
                    </Route>
                </Route>
            </Route>
        </Routes> 
    </BrowserRouter>
)

export default DashboardApp

import React, { useState, useEffect } from 'react';

import { Avatar, Divider, Box, Image, Switch, Card, Grid, Badge, Flex, Col } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating'

import { FiLink, FiEdit, FiTrash, FiEye, FiMoreVertical, } from "react-icons/fi";
import { FaFilePdf } from 'react-icons/fa';

import HistoryTab from './HistoryPage/tab';


// Utility-Service
import { getToken } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service
import { mechanicDetail, UpdateStatusService } from '../../../../service/mechanic';

// Redux-Settings
import {
    setMechanicDetail,
    selectStateData,
} from '../../../../redux/mechanicSlice';

// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';

const MechanicDetail = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const userDetail = useSelector(selectStateData);
    const { id } = useParams()

    const token = getToken();

    const getSingleData = async () => {
        const result = await mechanicDetail({ id, token });
        if (result?.data) {
            setLoading(false);
            dispatch(setMechanicDetail(result?.data.data))
        } else {
            setLoading(false);
            ErrorToast(result);
        }
    };
    useEffect(() => {
        getSingleData();
        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, []);

    const statusUpdate = async (item) => {
        const statusUpdateresult = await UpdateStatusService({ id: item.id, token });
        if (statusUpdateresult?.data?.status) {
            ErrorToast(statusUpdateresult?.data?.message);
            SuccessToast()
        } else {
            ErrorToast(statusUpdateresult);
        }
    };

    // console.log("UserDetail =>", userDetail?.driving_license)


    if (userDetail?.mechanic_type === 'MECHANIC') {
        return (
            <div>
                <Grid style={{ margin: 2 }}>
                    <Grid.Col xs={12}>
                        <h3>Profile Detail</h3>
                    </Grid.Col>

                    <Grid.Col xs={12}>
                        <p> <Badge color="orange">Mechanic</Badge></p>
                    </Grid.Col>
                    <Grid.Col xs={6} style={{ display: 'flex', flexDirection: 'row', gap: 6 }}>
                        <div>
                            <a href={userDetail?.profile_pic} target="_blank" rel="noopener noreferrer">
                                <Avatar variant="outline" size="xl" color="cyan" radius="xl" src={userDetail?.profile_pic} />
                            </a>
                        </div>
                        <div>
                            <p style={{ margin: '0' }}>{userDetail?.company_name ? userDetail?.company_name : '-'}</p>
                            <p style={{ margin: '0' }}>{userDetail?.phone ? userDetail?.phone : '-'}</p>
                            <p>{userDetail?.email ? userDetail?.email : '-'}</p>
                        </div>

                    </Grid.Col>

                    {/* Rating */}
                    <Grid.Col xs={6}>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <strong style={{ margin: '0' }}>Rating : </strong>
                            <Rating readonly={userDetail.avg_rating >= 0} size={25}
                                initialValue={parseFloat(userDetail.avg_rating)}
                                allowFraction />
                            {(Math.round(userDetail.avg_rating * 10) / 10).toFixed(1)}

                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <strong style={{ margin: '0' }}>How did you hear about us : </strong>
                            {userDetail?.heard_about_us_from}                            

                        </div>
                       

                    </Grid.Col>

                    {/* Divider */}
                    <Grid.Col xs={12}>
                        <Divider my="sm" />
                    </Grid.Col>

                    {/* SSM NUMBER */}
                    <Grid.Col xs={6}>
                        <div>
                            <p style={{ margin: '0' }}>IC NUMBER : {userDetail?.ic_number ? userDetail?.ic_number : '-'}</p>
                            <p style={{ margin: '0' }}>SSM NUMBER: {userDetail?.ssm ? userDetail?.ssm : '-'}</p>
                        </div>

                    </Grid.Col>

                    {/* WALLET */}
                    <Grid.Col xs={6}>
                        <div>
                            <p style={{ margin: '0' }}>Wallet Amount</p>
                            {userDetail?.wallet_balance ? <p style={{ margin: '0' }}> {`RM ${userDetail.wallet_balance?.wallet_balance}`}</p> : <p style={{ margin: '0' }}> 0.00</p>}

                        </div>
                    </Grid.Col>

                </Grid>


                {/* Additional Information Added */}
                <Grid style={{ marginTop: '20px ' }}>

                    <Grid.Col xs={6}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>IC Image</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.ic_images && userDetail.ic_images.length > 0 ? (
                                    <div className="ssmImageContainer">
                                        {userDetail.ic_images.map(item => (
                                            <a
                                                key={item.name}
                                                href={item.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="ssmClass"
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                            >
                                                {item.path.endsWith('.pdf') ? (
                                                    <FaFilePdf size={50} color="red" /> // Display PDF icon
                                                ) : (
                                                    <img
                                                        src={item.path}
                                                        alt="IC Document"
                                                        className="ssmImage"
                                                    />
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No IC Image</p>
                                )}
                            </div>
                        </Card>
                    </Grid.Col>

                    <Grid.Col xs={6}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>SSM Image</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.ssm_documents && userDetail.ssm_documents.length > 0 ? (
                                    <div className="ssmImageContainer">
                                        {userDetail.ssm_documents.map(item => (
                                            <a
                                                key={item.id}
                                                href={item.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="ssmClass"
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                            >
                                                {item.path.endsWith('.pdf') ? (
                                                    <FaFilePdf size={50} color="red" /> // Display PDF icon
                                                ) : (
                                                    <img
                                                        src={item.path}
                                                        alt="SSM Document"
                                                        className="ssmImage"
                                                    />
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No SSM Document</p>
                                )}
                            </div>
                        </Card>
                    </Grid.Col>

                    <Grid.Col xs={6}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>Driving Licence</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.driving_license && userDetail.driving_license.length > 0 ? (
                                    <div className="ssmImageContainer">
                                        {userDetail.driving_license.map(item => (
                                            <a
                                                className="ssmClass"
                                                key={item.name}
                                                href={item.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                            >
                                                {item.path.endsWith('.pdf') ? (
                                                    <FaFilePdf size={50} color="red" />
                                                ) : (
                                                    <img
                                                        src={item.path}
                                                        alt="Driving License"
                                                        className="ssmImage"
                                                    />
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No Driving Licence</p>
                                )}
                            </div>
                        </Card>
                    </Grid.Col>


                    <Grid.Col xs={6}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>Current Utility Bill</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.utility_bills && userDetail.utility_bills.length > 0 ? (
                                    <div className="ssmImageContainer">
                                        {userDetail.utility_bills.map(item => (
                                            <a
                                                className="ssmClass"
                                                key={item.name}
                                                href={item.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                            >
                                                {item.path.endsWith('.pdf') ? (
                                                    <FaFilePdf size={50} color="red" /> // Display PDF icon for PDF files
                                                ) : (
                                                    <img
                                                        src={item.path}
                                                        alt="Utility Bill"
                                                        className="ssmImage" // Display image for non-PDF files
                                                    />
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No Utility Bill</p>
                                )}
                            </div>
                        </Card>
                    </Grid.Col>


                    <Grid.Col xs={6}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>Qualification Certificate</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.qualification_certificate && userDetail.qualification_certificate.length > 0 ? (
                                    <div className="ssmImageContainer">
                                        {userDetail.qualification_certificate.map(item => (
                                            <a
                                                className="ssmClass"
                                                key={item.name}
                                                href={item.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                            >
                                                {item.path.endsWith('.pdf') ? (
                                                    <FaFilePdf size={50} color="red" /> // Display PDF icon for PDF files
                                                ) : (
                                                    <img
                                                        src={item.path}
                                                        alt="Qualification Certificate"
                                                        className="ssmImage" // Display image for non-PDF files
                                                    />
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No Certificate</p>
                                )}
                            </div>
                        </Card>
                    </Grid.Col>

                    <Grid.Col xs={6}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>Liability Insurance</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.liability_insurance && userDetail.liability_insurance.length > 0 ? (
                                    <div className="ssmImageContainer">
                                        {userDetail.liability_insurance.map(item => (
                                            <a
                                                className="ssmClass"
                                                key={item.name}
                                                href={item.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                            >
                                                {item.path.endsWith('.pdf') ? (
                                                    <FaFilePdf size={50} color="red" /> // Display PDF icon for PDF files
                                                ) : (
                                                    <img
                                                        src={item.path}
                                                        alt="Liability Insurance"
                                                        className="ssmImage" // Display image for non-PDF files
                                                    />
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No Insurance</p>
                                )}
                            </div>
                        </Card>
                    </Grid.Col>


                </Grid>
                <Grid>

                    <Grid.Col xs={12}>
                        <Card>
                            <div>
                                <h6 style={{ marginTop: '0.9rem', marginBottom: '0.5rem' }}>Services</h6>
                                <Divider my="sm" />
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {userDetail?.services?.map(item => (
                                        <div className='serviceClass'>

                                            <div>
                                                <span>

                                                    <Image
                                                        src={item.image}
                                                        alt="Avatar Thumb"
                                                        sx={(_) => ({
                                                            height: '60px',
                                                            width: '50px',
                                                            overflow: 'hidden',
                                                            margin: 'auto',
                                                        })}
                                                    />

                                                </span>
                                                <p>{item.name}</p>

                                                <p>{item.other_services}</p>

                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Card>
                    </Grid.Col>

                </Grid>
                <Grid>
                    <Grid.Col>
                        <Card>
                            <HistoryTab id={id} />
                        </Card>
                    </Grid.Col>

                </Grid>
            </div >
        )
    }

    return (
        <>
            <div>
                <Grid style={{ margin: '2px' }}>
                    <Grid.Col xs={12}>
                        <h3>Profile Detail</h3>
                    </Grid.Col>

                    <Grid.Col xs={12}>
                        <p> <Badge color="orange">Freelancer</Badge></p>
                    </Grid.Col>


                    {/* Freelancer Detail */}
                    <Grid.Col xs={6} style={{ display: 'flex', flexDirection: 'row', gap: 6 }}>
                        <div>
                            <a href={userDetail?.profile_pic} target="_blank" rel="noopener noreferrer">
                                <Avatar variant="outline" size="xl" color="cyan" radius="xl" src={userDetail?.profile_pic} />
                            </a>
                        </div>

                        <div>
                            <p style={{ margin: '0' }}>{userDetail?.legal_name ? userDetail?.legal_name : '-'}</p>
                            <p style={{ margin: '0' }}>{userDetail?.phone ? userDetail?.phone : '-'}</p>
                            <p>{userDetail?.email ? userDetail?.email : '-'}</p>
                        </div>

                    </Grid.Col>

                    {/* Rating */}
                    <Grid.Col xs={6}>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <strong style={{ margin: '0' }}>Rating : </strong>
                            <Rating readonly={userDetail.avg_rating >= 0} size={25}
                                initialValue={parseFloat(userDetail.avg_rating)}
                                allowFraction />
                            {(Math.round(userDetail.avg_rating * 10) / 10).toFixed(1)}

                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <strong style={{ margin: '0' }}>How did you hear about us : </strong>
                            {userDetail?.heard_about_us_from}                            

                        </div>
                       

                    </Grid.Col>


                    {/* Divider */}
                    <Grid.Col xs={12}>
                        <Divider my="sm" />
                    </Grid.Col>

                    {/* SSM NUMBER */}
                    <Grid.Col xs={6}>
                        <div>
                            <p style={{ margin: '0' }}>IC NUMBER : {userDetail?.ic_number ? userDetail?.ic_number : '-'}</p>
                            <p style={{ margin: '0' }}>SSM NUMBER: {userDetail?.ssm ? userDetail?.ssm : '-'}</p>
                        </div>

                    </Grid.Col>

                    {/* WALLET */}
                    <Grid.Col xs={6}>
                        <div>
                            <p style={{ margin: '0' }}>Wallet Amount</p>
                            {userDetail?.wallet_balance ? <p style={{ margin: '0' }}> {`RM ${userDetail.wallet_balance?.wallet_balance}`}</p> : <p style={{ margin: '0' }}> 0.00</p>}

                        </div>
                    </Grid.Col>

                </Grid>

                <Grid style={{ marginTop: '20px ' }}>

                    <Grid.Col xs={12}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>Experience Summary</h6>
                                <Divider my="sm" />
                                {userDetail?.experience_summary ? <p style={{ margin: '0' }}>{userDetail?.experience_summary}</p> : <p style={{ margin: '0' }}>--</p>}

                            </div>
                        </Card>
                    </Grid.Col>

                </Grid>

                {/* Additional Information Added */}
                <Grid style={{ marginTop: '20px ' }}>

                    <Grid.Col xs={6}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>IC Image</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.ic_images && userDetail.ic_images.length > 0 ? (
                                    <div className="ssmImageContainer">
                                        {userDetail.ic_images.map(item => (
                                            <a
                                                key={item.name}
                                                href={item.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="ssmClass"
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                            >
                                                {item.path.endsWith('.pdf') ? (
                                                    <FaFilePdf size={50} color="red" /> // Display PDF icon
                                                ) : (
                                                    <img
                                                        src={item.path}
                                                        alt="IC Document"
                                                        className="ssmImage"
                                                    />
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No IC Image</p>
                                )}
                            </div>
                        </Card>
                    </Grid.Col>

                    <Grid.Col xs={6}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>SSM Image</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.ssm_documents && userDetail.ssm_documents.length > 0 ? (
                                    <div className="ssmImageContainer">
                                        {userDetail.ssm_documents.map(item => (
                                            <a
                                                key={item.id}
                                                href={item.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="ssmClass"
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                            >
                                                {item.path.endsWith('.pdf') ? (
                                                    <FaFilePdf size={50} color="red" /> // Display PDF icon
                                                ) : (
                                                    <img
                                                        src={item.path}
                                                        alt="SSM Document"
                                                        className="ssmImage"
                                                    />
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No SSM Document</p>
                                )}
                            </div>
                        </Card>
                    </Grid.Col>

                    <Grid.Col xs={6}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>Driving Licence</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.driving_license && userDetail.driving_license.length > 0 ? (
                                    <div className="ssmImageContainer">
                                        {userDetail.driving_license.map(item => (
                                            <a
                                                className="ssmClass"
                                                key={item.name}
                                                href={item.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                            >
                                                {item.path.endsWith('.pdf') ? (
                                                    <FaFilePdf size={50} color="red" />
                                                ) : (
                                                    <img
                                                        src={item.path}
                                                        alt="Driving License"
                                                        className="ssmImage"
                                                    />
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No Driving Licence</p>
                                )}
                            </div>
                        </Card>
                    </Grid.Col>


                    <Grid.Col xs={6}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>Current Utility Bill</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.utility_bills && userDetail.utility_bills.length > 0 ? (
                                    <div className="ssmImageContainer">
                                        {userDetail.utility_bills.map(item => (
                                            <a
                                                className="ssmClass"
                                                key={item.name}
                                                href={item.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                            >
                                                {item.path.endsWith('.pdf') ? (
                                                    <FaFilePdf size={50} color="red" /> // Display PDF icon for PDF files
                                                ) : (
                                                    <img
                                                        src={item.path}
                                                        alt="Utility Bill"
                                                        className="ssmImage" // Display image for non-PDF files
                                                    />
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No Utility Bill</p>
                                )}
                            </div>
                        </Card>
                    </Grid.Col>


                    <Grid.Col xs={6}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>Qualification Certificate</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.qualification_certificate && userDetail.qualification_certificate.length > 0 ? (
                                    <div className="ssmImageContainer">
                                        {userDetail.qualification_certificate.map(item => (
                                            <a
                                                className="ssmClass"
                                                key={item.name}
                                                href={item.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                            >
                                                {item.path.endsWith('.pdf') ? (
                                                    <FaFilePdf size={50} color="red" /> // Display PDF icon for PDF files
                                                ) : (
                                                    <img
                                                        src={item.path}
                                                        alt="Qualification Certificate"
                                                        className="ssmImage" // Display image for non-PDF files
                                                    />
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No Certificate</p>
                                )}
                            </div>
                        </Card>
                    </Grid.Col>

                    <Grid.Col xs={6}>
                        <Card>
                            <div>
                                <h6 style={{ marginBottom: '0.5rem' }}>Liability Insurance</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.liability_insurance && userDetail.liability_insurance.length > 0 ? (
                                    <div className="ssmImageContainer">
                                        {userDetail.liability_insurance.map(item => (
                                            <a
                                                className="ssmClass"
                                                key={item.name}
                                                href={item.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                                            >
                                                {item.path.endsWith('.pdf') ? (
                                                    <FaFilePdf size={50} color="red" /> // Display PDF icon for PDF files
                                                ) : (
                                                    <img
                                                        src={item.path}
                                                        alt="Liability Insurance"
                                                        className="ssmImage" // Display image for non-PDF files
                                                    />
                                                )}
                                            </a>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No Insurance</p>
                                )}
                            </div>
                        </Card>
                    </Grid.Col>


                </Grid>

                <Grid>
                    <Col>
                        <Card>
                            <div>
                                <h6 style={{ marginTop: '0.9rem', marginBottom: '0.5rem' }}>Services</h6>
                                <Divider my="sm" />
                                {userDetail && userDetail.services && userDetail.services.length > 0 ? (
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {userDetail.services.map(item => (
                                            <div className='serviceClass' key={item.id}>
                                                <div>
                                                    <span>
                                                        <Image
                                                            src={item.image}
                                                            alt="Avatar Thumb"
                                                            sx={(_) => ({
                                                                height: '60px',
                                                                width: '60px',
                                                                overflow: 'hidden',
                                                                margin: 'auto',
                                                            })}
                                                        />
                                                    </span>
                                                    <p>{item.name}</p>
                                                    {item?.other_services &&
                                                        <p>{item.other_services}</p>
                                                    }

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No services available</p>
                                )}
                            </div>
                        </Card>
                    </Col>
                </Grid>

                <Grid>
                    <Grid.Col>

                        <HistoryTab id={id} />

                    </Grid.Col>

                </Grid>
            </div>
        </>
    )
};

export default MechanicDetail;